import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Web_User_Interface/720p_Series/Network/DDNS/DangerBox';
import DDNSTable from 'components/Web_User_Interface/720p_Series/Network/DDNS/ddnsTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // DDNS",
  "path": "/Web_User_Interface/720p_Series/Network/DDNS/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "You have two ways to access your camera over the internet - the easy way is called P2P. The only way to get complete access to all your camera´s functions is called DDNS. The DDNS or Internet Address gives you access to your cameras web user interface.",
  "image": "./WebUI_720p_SearchThumb_Network_DDNS.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Network_DDNS.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Network Menu // DDNS' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='You have two ways to access your camera over the internet - the easy way is called P2P. The only way to get complete access to all your camera´s functions is called DDNS. The DDNS or Internet Address gives you access to your cameras web user interface.' image='/images/Search/WebUI_720p_SearchThumb_Network_IP_Configuration.png' twitter='/images/Search/WebUI_720p_SearchThumb_Network_IP_Configuration.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Netzwerk/DDNS/' locationFR='/fr/Web_User_Interface/720p_Series/Network/DDNS/' crumbLabel="DDNS" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <h3 {...{
      "id": "ddns",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ddns",
        "aria-label": "ddns permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DDNS`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`You have two ways to access your camera over the internet - the easy way is called `}<a to="/Web_User_Interface/720p_Series/Network/P2P/">{`P2P`}</a>{`. The only way to get complete access to all your camera´s functions is called DDNS. The DDNS or Internet Address gives you access to your cameras web user interface.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/70cb5274acaf0590e86137d6cbc9b100/6bbf7/WebUI-Network_DDNS.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAADN0lEQVQ4y4WT20/iWBjA+dt297/Y3WTcF33zhScTExN5MTE+OBtkx4yZEm8xIjuJI7RQSqiFXuhpbaEthIK9AT1tp9yMj25o13E1m5nf03eS88t3vstJ5HK5X3/7/aeff3m38sfh4WGxWJQlSdW0fr+v63pPj+gtiY69zc3Nvb29o6Ojvz58SFxfX2cymd3dXSSbxTBMkiRd1y3T8j0fQhgEwddnfN9/eHggCOLi4oIkSRzHE+fn5ycnJ/l8vkpUWZbtdru2bSstRVFUXdfhf3BddzKZUBRVLpdlWQYAJBAE2X///vj4GEWL9Xq90+n0ej1BEO7EO0EQe73eIOI+Yjwe12o1FEVFUeQ4LsEyDEmSoihqqkbTtKZpnuf19f794H7oDG3Lti3HtmzTNAf9AXS929vbF7ndauFlHEXRltwSBEFRlEmEaZqFQqFSqaAoimFYq9WaTqfz+fyVjKLFPyNwHBdFUVGUMAxns5miKDs7O6lUant7e2Nj4+bmZh7xSg6Dr4vF4unp6fHxkeM4VVVjWZbldDqdTCZXVla2trZwHJ/NZm9lDEMvLy+r1Wqz2aRpOpbn87ksy6lUKp1Or66urq2t8Tw/mUwWi8UrGfn0KZPJIAhy/eVLLC8vPSwkWVpfX9/f3z84OEgmk6Io/k/NZ6dnWQTJ5XKFQiHuNnTh0BmqqkY3aOlOupMklmXb7XYYhm8zZ5Hs0cePf3/+XCwWG41GPCrbsqELw3ASBEGccDqd+r7/Vs7n8+dnZ6cnpyhWYph/52zbtud5QRD4vu9FxMFsNiNJ8kUGABAEUavVAAAMw2ia5rrjfr9vGoZlWfF6x/i+/7bmarWKomihUKjX6xzHaaoGITRNcxgxHo+/J3Ncs1QqEwTB8zzLssuGQWgYhuM4ztAZjUbfkymKwkolvFJhGOZZdg3DsB3bcZwfZAYAkCTZaDS+1Qyhu/xGg4Ft2z+QKYrCcbxSqbBRZlVVPc9bPjliNB55z0AIp9Ppq24LgsA3eQEIPM8zDNPtdi3LUhRF07ROpyOKIrvs4pJ2u20YBkVRL/LSodkmx9M0zXEcACBeciaC/hZFMQDg6uqqVCrF8j9yQPbA8NqvUgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/70cb5274acaf0590e86137d6cbc9b100/e4706/WebUI-Network_DDNS.avif 230w", "/en/static/70cb5274acaf0590e86137d6cbc9b100/d1af7/WebUI-Network_DDNS.avif 460w", "/en/static/70cb5274acaf0590e86137d6cbc9b100/04ac8/WebUI-Network_DDNS.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/70cb5274acaf0590e86137d6cbc9b100/a0b58/WebUI-Network_DDNS.webp 230w", "/en/static/70cb5274acaf0590e86137d6cbc9b100/bc10c/WebUI-Network_DDNS.webp 460w", "/en/static/70cb5274acaf0590e86137d6cbc9b100/d8378/WebUI-Network_DDNS.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/70cb5274acaf0590e86137d6cbc9b100/81c8e/WebUI-Network_DDNS.png 230w", "/en/static/70cb5274acaf0590e86137d6cbc9b100/08a84/WebUI-Network_DDNS.png 460w", "/en/static/70cb5274acaf0590e86137d6cbc9b100/6bbf7/WebUI-Network_DDNS.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/70cb5274acaf0590e86137d6cbc9b100/6bbf7/WebUI-Network_DDNS.png",
              "alt": "Web User Interface - 720p Series - Network DDNS",
              "title": "Web User Interface - 720p Series - Network DDNS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig1.`}</strong>{` `}<em parentName="p">{`The DDNS Menu - Use your personal Internet Address to connect to your camera over the internet.`}</em></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DDNSTable mdxType="DDNSTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Your camera comes with a personal `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`DDNS Address`}</a>{` - e.g. `}<a parentName="p" {...{
        "href": "http://xxxx77.ddns-instar.de."
      }}>{`http://xxxx77.ddns-instar.de.`}</a>{` Everything you need for a remote access to your camera is a `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port Forwarding Rule`}</a>{` in your router. You can access your camera afterwards through the internet using this http address. Please visit the DDNS section for detailed information on the `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port Forwarding`}</a>{` setup as well as video instruction for many common routers. Or refer to your router's user manual for further guidelines on how to set up a port forwarding rule.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`In case you want to use an account from `}<a href="http://dyn.com/dns/" target="_blank" rel="noopener noreferrer">{`DYNDNS`}</a>{` simply choose the third party service and type in your personal login credentials. By doing so you will temporarily `}<strong parentName="p">{`deactivate your INSTAR DDNS address`}</strong>{`. In case you are using several cameras behind a single internet access point, please set up the third party address in `}<strong parentName="p">{`only one of the cameras`}</strong>{`. Or if possible directly inside your router! All your cameras will be accessible through this address - just assign a unique HTTP port to every camera. For example, if camera 1 is assigned the HTTP port 85 and camera 2 the HTTP port 86, use myaddress.dyndns.org:85 to reach camera 1 and myaddresse.dyndns.org:86 to be forwarded to camera 2.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      